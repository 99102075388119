import "./CharacterCard.css";
import elements from "../assets/elements.json";

const CharacterCard = (props) => {
  const character = props.character;

  return (
    <div
      className={`m-2 character text-gray-900 ${
        character.name === "Aloy"
          ? "rarity-5a"
          : character.rarity === "5"
          ? "rarity-5"
          : "rarity-4"
      }`}
    >
      <img
        className="vision"
        src={elements[character.vision].base64}
        alt={character.vision}
      />
      <img src={character.icon} alt={character.name} />
      <p className="truncate text-center px-0 mx-1">{character.name}</p>
    </div>
  );
};

export default CharacterCard;
