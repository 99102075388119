import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [location, setLocation] = useState("Home");
  const routerLocation = useLocation();

  useEffect(() => {
    switch (routerLocation.pathname) {
      case "/about":
        setLocation("About");
        break;
      default:
        setLocation("Home");
    }
  }, [routerLocation.pathname]);

  return (
    <>
      <div className="flex w-full max-w-5xl my-10 mx-auto px-10">
        <div>
          <h1 className="text-3xl text-green-500 border-2 border-dashed border-green-700 rounded-full px-2">
            <b>K</b>
            <span className="text-green-700">usanali</span>
          </h1>
        </div>
        <div className="relative w-full">
          <ul className="flex absolute h-full top-0 right-0">
            <li
              className={`header-item ${
                location === "Home" ? "header-item-active" : ""
              }`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`header-item ${
                location === "About" ? "header-item-active" : ""
              }`}
            >
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
