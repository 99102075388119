import { useEffect, useState } from "react";

const CharacterGuide = (props) => {
  const character = props.character;
  const [builds, setBuilds] = useState([]);
  const [currentBuild, setCurrentBuild] = useState("");

  useEffect(() => {
    if (currentBuild === "" && builds.length > 0) {
      setCurrentBuild(builds[0]);
    }
  }, [builds, currentBuild]);

  useEffect(() => {
    setBuilds(["Test Build 1", "Test Build 2", "Test Build 3"]);
  }, []);

  return (
    <>
      <div className="guide-content-tabs flex">
        {builds.map((build, key) => (
          <div
            className={`${build === currentBuild ? "active-tab" : ""}`}
            key={key}
            onClick={() => setCurrentBuild(build)}
          >
            {build}
          </div>
        ))}
      </div>
      <div className="guide-content-build">
        <p>Builds for {character.name} will be shown here.</p>
      </div>
    </>
  );
};

export default CharacterGuide;
