const Footer = () => {
  return (
    <div className="w-100 text-center mt-10 mb-4">
      <p className="font-bold text-green-500">Made with ❤ by WildDanger 🤪</p>
      <p className="text-sm text-slate-400">
        Genshin Impact™ is a registered trademark of MiHoYo Co., Ltd. This site
        is not affiliated with or endorsed by miHoYo. Images and data © MiHoYo
        Co., Ltd.
      </p>
    </div>
  );
};

export default Footer;
