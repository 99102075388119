import CharacterCard from "./CharacterCard";
import characterdb from "../assets/characters.json";
import { Link, Outlet } from "react-router-dom";

const CharacterList = () => {
  return (
    <>
      <Outlet />
      <div className="flex flex-wrap items-center justify-center px-3 max-w-screen-2xl mx-auto">
        {Object.keys(characterdb).map((character) => (
          <Link to={`/character/${character}`} key={character}>
            <CharacterCard character={characterdb[character]} />
          </Link>
        ))}
      </div>
    </>
  );
};

export default CharacterList;
