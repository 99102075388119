import characterDb from "../assets/characters.json";
import elements from "../assets/elements.json";
import { Link, useParams } from "react-router-dom";
import "./Character.css";
import CharacterGuide from "./CharacterGuide";

const Character = () => {
  const { id } = useParams();
  const character = characterDb[id];

  if (character === undefined) {
    return <></>;
  }

  return (
    <>
      <Link to="/">
        <div className="fixed z-40 w-screen h-screen inset-0 bg-black bg-opacity-75 cursor-default"></div>
      </Link>

      <div className={`guide guide-${character.vision.toLowerCase()}`}>
        <div className="guide-titlebar">
          <div className="guide-titlebar-left">
            <div className="guide-titlebar-image">
              <img src={character.icon} alt={character.name} />
            </div>
            <div className="guide-titlebar-text">
              <h1>{character.name}</h1>
              <p>
                <span>
                  {character.vision}
                  <img
                    src={elements[character.vision].base64}
                    alt={character.vision}
                  />
                </span>
                <span>{character.weapon}</span>
                <span>{character.rarity}⭐</span>
              </p>
            </div>
          </div>
          <div className="guide-titlebar-right">
            <Link to="/">
              <button id="close">
                <img src={require("../assets/close.svg").default} alt="close" />
              </button>
            </Link>
          </div>
        </div>
        <div className="guide-content">
          <CharacterGuide character={character} />
        </div>
      </div>
    </>
  );
};

export default Character;
