import { useEffect } from "react";
import "./App.css";
import Character from "./components/Character";
import CharacterList from "./components/CharacterList";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    document.body.classList.add("bg-zinc-900");
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<CharacterList />}>
          <Route path="character/:id" element={<Character />} />
        </Route>
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
